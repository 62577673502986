import { useState, useEffect, useRef } from "react";

const sign = (x) => x < 0 ? -1 : 1;

function scrollPos(): number {
    return (typeof window !== 'undefined' && window.scrollY) || 0;
}

function useHidingHeader(): boolean {
    const [visible, setVisible] = useState(true);
    const amountScrolledInCurrentDirection = useRef(0);
    const prevScrollPos = useRef(scrollPos());
    useEffect(() => {
        const handleScroll = () => {
            const prev = prevScrollPos.current;
            prevScrollPos.current = scrollPos();
            if (window.scrollY < 50) {
                if (!visible) {
                    setVisible(true);
                }
                return;
            }
            const delta = window.scrollY - prev;
            if (delta !== 0) {
                if (sign(delta) === sign(amountScrolledInCurrentDirection)) {
                    amountScrolledInCurrentDirection.current += delta;
                } else {
                    amountScrolledInCurrentDirection.current = delta;
                }
            }
            if (Math.abs(amountScrolledInCurrentDirection.current) > 20) {
                const shouldBeVisible = amountScrolledInCurrentDirection.current < 0;
                setVisible(shouldBeVisible);
            }
        };
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    return visible;
}
export default useHidingHeader;
