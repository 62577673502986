import React, { useState } from "react"
import styled from 'styled-components';
import { useWaypoints } from './useWaypoint';
import { Spring, animated, config } from 'react-spring'

import c1 from '../images/Constellation/c1.png';
import c2 from '../images/Constellation/c2.png';
import c3 from '../images/Constellation/c3.png';
import c4 from '../images/Constellation/c4.png';
import c5 from '../images/Constellation/c5.png';
import c6 from '../images/Constellation/c6.png';
import c7 from '../images/Constellation/c7.png';
import c8 from '../images/Constellation/c8.png';
import c9 from '../images/Constellation/c9.png';
import c10 from '../images/Constellation/c10.png';
import c11 from '../images/Constellation/c11.png';
import c12 from '../images/Constellation/c12.png';

export const IMAGES = [
    c2, c8, c1, c9, c3, c4, c5, c6, c7, c8, c12, c11, c10,
];

// aspect: width/height
const Container = styled.div<{ aspect: number }>`
    width: 100%;
    padding-bottom: ${props => (100 / props.aspect)}%;
    position: relative;
    // border: 1px solid red;
    margin-top: -4rem;
    margin-bottom: 1rem;
`;

const OffsetFromTop = styled.div<{ percent: number }>`
    position: absolute;
    left: 0;
    top: ${props => props.percent}%;
`;

const ImageEl = styled(animated.img)`
    position: absolute;
    width: 30%;
    margin-left: -15%;
    border-radius: 3%;
    box-shadow: 0 0 20px rgba(0,0,0,0.15);
    overflow: hidden;
`;

interface ImageSpec {
    key: string;
    x: string;
    y: string;
    src: string;
    scale: number;
    visibleAtTime: number;
}

function generateImageSpecs(): ImageSpec[] {
    let results: ImageSpec[] = [];

    const positions: [number, number][] = [
        [30, 0],
        [70, 5],
        [10, 5],
        // [90, 5],
        [50, 5],
        [90, 5],
        [12, 5],
        // [20, 5],
        [45, 5],
        [80, 5],

        [-10, 4],
        [60, 4],
        // [20, 4],
        [100, 3],
        // [50, 3],
        [20, 2],

        [100, 2],
        [20, 2],
        [-20, 3],
        // [45, 2],
        [33, 2],
        [70, 2],
        // [50, 2],
        [20, 2],
        // [110, 2],
        [90, 2],
        // [20, 2],
        [0, 2],
        [45, 2],
        [-10, 2],
    ];
    const scales = [1.2, 0.9, 1.3, 0.8, 1.4, 0.7];
    const visibleTimeOffsets = [0, 2, 5, 1];
    let totalY = 0;
    for (const [_, yDelta] of positions) {
        totalY += yDelta;
    }
    let i = 0;
    let y = 0;
    for (const [x, yDelta] of positions) {
        y += yDelta / totalY;
        const visibleTimeOffset = visibleTimeOffsets[results.length % visibleTimeOffsets.length];
        const t = y + visibleTimeOffset / 100; // 0.1 + (results.length % 3) / 3.0; // -1; // (tSection + Math.random()) / tSections;
        const scale = scales[results.length % scales.length]; // 1 + rand() * 0.4;
        const src = IMAGES[results.length % IMAGES.length] // IMAGES[Math.floor(Math.random() * IMAGES.length)];
        results.push({
            key: `${i++}`,
            x: `${x}%`,
            y: `${y * 100}%`,
            src,
            scale,
            visibleAtTime: t,
        });
    }
    return results;
}

function roundToInterval(x: number, interval: number): number {
    return Math.round(x / interval) * interval;
}

const Image = ({src, x, y, visible, visibleAtTime, scale}: ImageSpec & {visible: boolean}) => {
    const transform = `translateY(${visible ? 0 : '100px'}) scale(${visible ? scale : 0.01})`;
    return (
        <Spring native to={{transform}} config={config.stiff}>
            { props => <ImageEl src={src} style={{...props, left: x, top: y, zIndex: Math.round(scale * 50)}} /> }
        </Spring>
    )
}

const Constellation = ({children}) => {
    const [imageSpecs, _] = useState<ImageSpec[]>(() => generateImageSpecs());
    const [t, setT] = useState(0);
    const timeWaypoints = useWaypoints<number>(status => {
        const progress = roundToInterval(status.from + status.progress, 0.01);
        setT(progress);
    });
    return (
        <Container aspect={0.8}>
            <OffsetFromTop percent={0}>{timeWaypoints.renderWaypoint(0, -60)}</OffsetFromTop>
            {imageSpecs.map(({key, x, y, scale, src, visibleAtTime}) => {
                return <Image key={key} x={x} y={y} scale={scale} src={src} visibleAtTime={visibleAtTime} visible={t >= visibleAtTime} />;
            })}
            <OffsetFromTop percent={100}>{timeWaypoints.renderWaypoint(1, -60)}</OffsetFromTop>
            {children}
        </Container>
    )
}

export default Constellation;
