import React, { useLayoutEffect, useRef, MutableRefObject, useEffect } from "react"

interface WaypointTracker<T> {
    nodeRef: MutableRefObject<HTMLElement>;
    id: T;
    offsetVH: number;
}

interface WaypointStatus<T> {
    from: T;
    to?: T;
    progress: number;
}

let prevId = 0;
function assignID(): string {
    return `${prevId++}`;
}

function WaypointComponent<T>({manager, id, offsetVH}: {manager: WaypointManager<T>, id: T, offsetVH?: number}) {
    const nodeRef = useRef<HTMLDivElement>();
    useLayoutEffect(() => {
        const waypointId = assignID();
        manager.trackers[waypointId] = {nodeRef, id, offsetVH: offsetVH || 0};
        manager.didUpdate();
        return () => {
            delete manager.trackers[waypointId];
            manager.didUpdate();
        };
    }, [manager, id]);
    return <div ref={nodeRef} />;
}

export class WaypointManager<T> {
    trackers: {[key: string]: WaypointTracker<T>};
    onUpdate?: (() => void);
    updateScheduled = false;
    constructor() {
        this.trackers = {};
    }
    renderWaypoint(id: T, offsetVH: number = 0): React.ReactNode {
        return <WaypointComponent manager={this} id={id} offsetVH={offsetVH} />;
    }
    didUpdate() {
        if (this.updateScheduled) return;
        this.updateScheduled = true;
        requestAnimationFrame(() => {
            this.updateScheduled = false;
            if (this.onUpdate) {
                this.onUpdate();
            }
        });
    }
}

export function useWaypoints<T>(callback: (status: WaypointStatus<T>) => void): WaypointManager<T> {
    const waypointMgrRef = useRef<WaypointManager<T>>(undefined);
    if (!waypointMgrRef.current) {
        waypointMgrRef.current = new WaypointManager();
    }
    const mgr = waypointMgrRef.current;

    mgr.onUpdate = () => {
        const scrollY = window.scrollY;
        let allItemsPositions: {id: T, y: number}[] = [];
        for (const key of Object.keys(mgr.trackers)) {
            const {nodeRef, id, offsetVH} = mgr.trackers[key];
            if (nodeRef.current) {
                const y = nodeRef.current.getBoundingClientRect().top + scrollY + (offsetVH / 100) * window.innerHeight;
                allItemsPositions.push({y, id: id});
            }
        }
        if (allItemsPositions.length === 0) return;
        let from: {id: T, y: number} = allItemsPositions[0];
        let to: {id: T, y: number} | undefined;
        for (const {id, y} of allItemsPositions) {
            if (y <= scrollY) {
                from = {id, y};
            } else if (!to && y > scrollY) {
                to = {id, y};
            }
        }
        if (from && to) {
            const progress = to.y === from.y ? 0 : (scrollY - from.y) / (to.y - from.y);
            callback({from: from.id, to: to.id, progress});
        } else {
            callback({from: from.id, progress: (scrollY >= from.y ? 1 : 0)});
        }
    };

    useLayoutEffect(() => {
        const handleScroll = () => {
            mgr.onUpdate();
        };
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
      }, [mgr]);
    
    return mgr;
}
