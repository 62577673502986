import React, { useState } from "react"
import styled from 'styled-components';
import { useWaypoints } from './useWaypoint';
import { Spring, animated } from 'react-spring'
import { IMAGES } from "./Constellation";

const ImageEl = styled(animated.img)`
    position: absolute;
    width: 30vw;
    height: 30vw;
    margin-left: -15vw;
    margin-top: -15vw;
    border-radius: 3%;
    box-shadow: 0 0 20px rgba(0,0,0,0.15);
    overflow: hidden;
`;

interface Item {
    page: number;
    scale: number;
    src: string;
    x: number;
    y: number;
}

const scales = [1.2, 1.5, 1.3, 0.9, 1];

const specs: [number, number, number, number?][] = [
    [50, 50, 0], 
    [95, 60, 1], 
    [40, 70, 1], 
    [10, 65, 1], 
    [30, 35, 1], 
    [0, 80, 2], 
    [60, 40, 2], 
    [20, 25, 2],
    [40, 30, 2],
    [40, 34, 2],
    [30, 60, 2],
    [70, 70, 2],
    [90, 40, 2],
    [20, 50, 2],
    [67, 52, 2],
];

const items: Item[] = specs.map(([x, y, page], i) => ({
        page,
        x,
        y,
        scale: scales[i % scales.length],
        src: IMAGES[i % IMAGES.length],
}));

const Image = ({src, x, y, visible, scale}: Item & {visible: boolean}) => {
    const transform = `translateY(${visible ? 0 : 0}) scale(${visible ? scale : 0.01})`;
    const opacity = visible ? 1 : 0;
    return (
        <Spring native to={{transform, opacity}}>
            { props => <ImageEl src={src} style={{
                ...props, 
                left: x + '%', 
                top: y + '%', 
                zIndex: Math.round(scale * 50),
                opacity: (props.opacity as any).interpolate((o) => o > 0 ? 1 : 0) // don't animate opacity
                }} /> }
        </Spring>
    )
}

const Container = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
`;

const ConstellationMobile = ({page}: {page: number}) => {
    return (
        <Container>
            {
                items.map((item, i) => <Image {...item} visible={page >= item.page} key={i} />)
            }
        </Container>
    )
}

export default ConstellationMobile;
