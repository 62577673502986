import React from "react"
import styled, { keyframes } from 'styled-components';
import ChevronImage from '../images/StoryHintChevron.svg';

const CHEVRON_SIZE = 4;

const Container = styled.div`
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: 0.33;
`;

const Chevron = styled.div<{left: boolean}>`
    position: absolute;
    height: ${CHEVRON_SIZE}vw;
    width: ${CHEVRON_SIZE}vw;
    margin-top: ${-CHEVRON_SIZE / 2}vw;
    top: 50%;
    background: url(${ChevronImage});
    background-size: contain;
    background-repeat: no-repeat;
    ${ props => props.left ? `left: 1vw` : `right: 1vw` };
    transform: ${props => props.left ? `rotate(90deg)` : `rotate(-90deg)`};
    transition: opacity 0.2s ease-in-out; 
`;

interface Props {
    canGoLeft: boolean;
    canGoRight: boolean;
}
const StoryHintOverlay = ({canGoLeft, canGoRight}) => (
    <Container>
        <Chevron left={true} style={{opacity: canGoLeft ? 1 : 0}} />
        <Chevron left={false} style={{opacity: canGoRight ? 1 : 0}} />
    </Container>
)
export default StoryHintOverlay;
