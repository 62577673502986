import React, { useState, useRef, useEffect } from "react"
import styled from 'styled-components';

export interface StoryPage {
    render(): React.ReactNode;
    id: string;
}

interface Props<T extends StoryPage> {
    pages: T[];
    pageId: string;
    setPageId(id: string): void;
}

const StoryContainer = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
`;

const Content = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
`;

function nodeContainsAnyAncestorsPassingTest(node: HTMLElement, upUntilNode: HTMLElement, test: (node: HTMLElement) => boolean): boolean {
    if (test(node)) return true;
    if (node.parentElement === upUntilNode || !node.parentElement) return false;
    return nodeContainsAnyAncestorsPassingTest(node.parentElement, upUntilNode, test);
}

function nodeShouldBlockStoryNavigation(node: HTMLElement): boolean {
    return node.tagName.toUpperCase() === 'A' || !!node.getAttribute('data-block-story-navigation');
}

function useLastTouchEventTracking(): [() => void, () => boolean] {
    const lastSeenTouchEvent = useRef(performance.now());
    const didSee = () => {
        lastSeenTouchEvent.current = performance.now();
    }
    const hasSeenRecently = () => performance.now() - lastSeenTouchEvent.current < 1000;
    return [didSee, hasSeenRecently];
}

const StoryControl = function<T extends StoryPage>({pages, pageId, setPageId}: Props<T>) {
    const curNode = useRef<HTMLDivElement>();
    const [didSeeTouchEvent, hasSeenTouchEventRecentlyFn] = useLastTouchEventTracking();
    
    let index = pages.findIndex(page => page.id === pageId);
    if (index === -1) {
        index = 0;
    }

    const advance = (n: number) => {
        const newIndex = Math.max(0, Math.min(pages.length - 1, index + n));
        const newPageId = pages[newIndex].id;
        setPageId(newPageId);
    }

    const touchStartLocation = useRef({x: 0, y: 0});

    const handlePointerPressStartAtPos = (pos: {x: number, y: number}) => {
        touchStartLocation.current = pos;
    };

    const handlePointerPressEndAtPos = (pos: {x: number, y: number}, target: HTMLElement) => {
        if (curNode.current && nodeContainsAnyAncestorsPassingTest(target as HTMLElement, curNode.current, nodeShouldBlockStoryNavigation)) {
            return; // story nav blocked
        }
        if (curNode.current) {
            const distFromStart = Math.sqrt(Math.pow(touchStartLocation.current.x - pos.x, 2) + Math.pow(touchStartLocation.current.y - pos.y, 2));
            if (distFromStart > 5) return;
            const rect = curNode.current.getBoundingClientRect();
            const xPct = (pos.x - rect.left) / rect.width;
            if (xPct < 0.3) {
                advance(-1);
            } else if (xPct >= 0.3) {
                advance(1);
            }
        }
    };

    const touchStart = (e: TouchEvent) => {
        didSeeTouchEvent();
        handlePointerPressStartAtPos({x: e.touches[0].clientX, y: e.touches[0].clientY});
    };

    const touchEnd = (e: TouchEvent) => {
        didSeeTouchEvent();
        const touch = e.changedTouches[0];
        const pos = {x: touch.clientX, y: touch.clientY};
        handlePointerPressEndAtPos(pos, e.target as HTMLElement);
    };

    const mouseDown = (e: MouseEvent) => {
        if (hasSeenTouchEventRecentlyFn()) return;
        handlePointerPressStartAtPos({x: e.clientX, y: e.clientY});
    };

    const mouseUp = (e: MouseEvent) => {
        if (hasSeenTouchEventRecentlyFn()) return;
        handlePointerPressEndAtPos({x: e.clientX, y: e.clientY}, e.target as HTMLElement);
    }

    const curPage = pages[index];
    return (
        <StoryContainer ref={curNode}>
            <Content onTouchStart={touchStart} onTouchEnd={touchEnd} onMouseDown={mouseDown} onMouseUp={mouseUp}>
                {curPage.render()}
            </Content>
        </StoryContainer>
    )
}

export default StoryControl;
