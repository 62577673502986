import React, { useState } from "react"
import styled from 'styled-components';
import { HELLO_EMAIL, JOBS_URL, NEWSLETTER_URL, TEAM_URL, TWITTER_URL, VALUES_URL } from "./Constants";
import StayInTouchField from "./StayInTouchField";
import useHidingHeader from './useHidingHeader';
import { Link } from "gatsby"
import GetArcButton from "./GetArcButton";

const HeaderDiv = styled.div`
    font-size: 16px;
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 1em;
    transition: opacity 0.2s ease-in-out;
`;

const ExternalLink = styled.a`
    text-decoration: none;
    color: inherit;
    padding: 0.5em 0.2em;
    margin-right: 1em;
    font-weight: 500;
`;

const LocalLink = styled(props => <Link {...props} />)`
    text-decoration: none;
    color: inherit;
    padding: 0.5em 0.2em;
    margin-right: 1em;
    font-weight: 500;
`;

interface Props {
    bgColor?: string;
    fgColor?: string;
}
const GlobalHeader = ({bgColor, fgColor}: Props) => {
    const headerVisible = useHidingHeader();
    return (
        <HeaderDiv style={{opacity: headerVisible ? 1 : 0, backgroundColor: bgColor || '#FCFCFF', color: fgColor || '#757575'}}>
            <ExternalLink href={VALUES_URL}>Values</ExternalLink>
            <ExternalLink href={HELLO_EMAIL}>Contact</ExternalLink>
            <LocalLink to={JOBS_URL}>Careers</LocalLink>
            <ExternalLink href={TWITTER_URL}>Twitter</ExternalLink>
            <ExternalLink href={NEWSLETTER_URL}>Newsletter</ExternalLink>
            <GetArcButton />
        </HeaderDiv>
    )
}

export default GlobalHeader;
