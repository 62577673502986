import React from "react"
import styled from 'styled-components';
import Crumbs from '../images/Crumbs.png'

const CrumbsContainer = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    pointer-events: none;
    overflow: hidden;
`;

const CrumbsImg = styled.img`
    width: 100%;
    min-width: 1200px;
    margin: 0;
    display: block;
`;

const BrowserImageryBottomOverlay = () => (
    <CrumbsContainer>
        <CrumbsImg src={Crumbs} />
    </CrumbsContainer>
)

export default BrowserImageryBottomOverlay;
