import React from "react"
import styled from 'styled-components';

import BrowserTopLeft from '../images/Dark/BrowserTopLeft.png';
import BrowserTopCenter from '../images/Dark/BrowserTopCenter.png';
import BrowserTopRight from '../images/Dark/BrowserTopRight.png';
import BrowserLeftEdge from '../images/Dark/BrowserLeftEdge.png';
import BrowserBottomLeft from '../images/Dark/BrowserBottomLeft.png';
import BrowserBottomCenter from '../images/Dark/BrowserBottomCenter.png';
import BrowserBottomRight from '../images/Dark/BrowserBottomRight.png';
import BrowserRightEdge from '../images/Dark/BrowserRightEdge.png';
import TabLeft from '../images/Dark/TabLeft.png';
import { remap, range } from "./utils";
import { useWindowSize } from "./useScreenSize";


export const WINDOW_HEIGHT_VH = {
    desktop: 70,
    mobile: 50,
    breakpoint: 600,
};

const FrameContainer = styled.div`
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: opacity 0.2s ease-in-out;
`;

const BrowserWindowDiv = styled.div`
    position: absolute;
    box-sizing: border-box;
    width: 100vw;
    font-size: 2rem;
    
    height: ${WINDOW_HEIGHT_VH.mobile}vh;
    @media screen and (min-width: ${WINDOW_HEIGHT_VH.breakpoint}px) {
        height: ${WINDOW_HEIGHT_VH.desktop}vh;
    }

    @media screen and (min-width: 1000px) {
        width: 80vw;
        // font-size: 4rem;
    }
`;

const Patch = styled.div<{src: string}>`
    position: absolute;
    background-image: url(${props => props.src});
    background-size: 100% 100%;
`;

const AbsContainer = styled.div`
    position: absolute;
    background-color: white;
`;

const Cover = styled.div`
    position: absolute;
    background-color: #131215;
`;

const TabDiv = styled.div`
    position: absolute;
    top: 0;
    height: 0.46em;
    display: flex;
    flex-direction: row;
`;

const TabCircle = styled.div`
    position: absolute;
    left: 0.21em;
    top: 0.14em;
    width: 0.17em;
    height: 0.18em;
    background-color: #E1E4EB;
    border-radius: 0.1em;
    opacity: 0.3;
`;

const TabTitle = styled.div`
    position: absolute;
    left: 0.47em;
    top: 0.14em;
    right: 0.3em;
    height: 0.18em;
    background-color: #E1E4EB;
    border-radius: 0.04em;
    opacity: 0.3;
`;

const Tab = ({left, width, opacity}: {left: string, width: string, opacity: number}) => {
    return (
        <TabDiv style={{left, width, opacity}}>
            <img src={TabLeft} style={{height: '100%'}} />
            <div style={{flexGrow: 1, alignSelf: 'stretch', backgroundColor: '#757880'}} />
            <img src={TabLeft} style={{height: '100%', transform: 'scaleX(-1) translateX(1px)'}} />
            <TabCircle />
            <TabTitle />
        </TabDiv>
    )
}

const TabsContainer = styled.div`
    position: absolute;
    top: 0.82em;
    left: 2.26em;
    right: 2.26em;
`;

const Tabs = ({count}: {count: number}) => {
    const spacingPct = 1;
    let leftPct = 0;
    const windowWidth = useWindowSize().innerWidth;
    const maxWidthPercent = windowWidth > 1600 ? 16 : 18;
    const tabWidth = remap(count, [5.5, 10], [maxWidthPercent, 7]);
    return(
        <TabsContainer>
            {range(10).map(i => {
                const appearance = remap(i + 1, [count, count + 1], [1, 0]);
                // console.log(`${i}, ${count}, ${appearance}`);
                const widthPct = remap(appearance, [0, 1], [2, tabWidth]);
                const opacity = remap(appearance, [0, 0.3], [0, 1]);
                const div = <Tab key={i} left={leftPct + '%'} width={widthPct + '%'} opacity={opacity} />
                leftPct += widthPct + spacingPct;
                return div;
            })}
        </TabsContainer>
    )
}

const Frame = ({tabCount, opacity}: {tabCount: number, opacity: number}) => {
    return (
        <FrameContainer style={{opacity}}>
            <BrowserWindowDiv>
                <AbsContainer style={{left: '0.75em'}}>
                    <Cover style={{right: 0, width: '100vw', top: '-100vh', height: '200vh'}} />
                </AbsContainer>
                <AbsContainer style={{right: '0.75em'}}>
                    <Cover style={{left: 0, width: '100vw', top: '-100vh', height: '200vh'}} />
                </AbsContainer>
                <AbsContainer style={{top: '0.75em'}}>
                    <Cover style={{bottom: 0, height: '100vh', left: '-100vw', width: '200vw'}} />
                </AbsContainer>
                <AbsContainer style={{bottom: '0.75em'}}>
                    <Cover style={{top: 0, height: '100vh', left: '-100vw', width: '200vw'}} />
                </AbsContainer>
                <Patch style={{top: 0, left: 0, width: '2.42em', height: '1.84em'}} src={BrowserTopLeft} />
                <Patch style={{top: 0, left: '2.42em', right: '2.42em', height: '1.84em'}} src={BrowserTopCenter} />
                <Patch style={{top: 0, right: 0, width: '2.42em', height: '1.84em'}} src={BrowserTopRight} />
                <Patch style={{top: '1.84em', left: 0, width: '1.32em', bottom: '1.07em'}} src={BrowserLeftEdge} />
                <Patch style={{top: '1.84em', right: 0, width: '2.42em', bottom: '1.07em'}} src={BrowserRightEdge} />
                <Patch style={{bottom: 0, left: 0, width: '1.32em', height: '1.07em'}} src={BrowserBottomLeft} />
                <Patch style={{bottom: 0, left: '1.32em', right: '2.42em', height: '1.07em'}} src={BrowserBottomCenter} />
                <Patch style={{bottom: 0, right: 0, width: '2.42em', height: '1.84em'}} src={BrowserBottomRight} />
                <Tabs count={tabCount} />
            </BrowserWindowDiv>
        </FrameContainer>
    )
}

export default Frame;
