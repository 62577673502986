import React from "react";
import styled from 'styled-components';
import { ARC_NET_URL } from "./Constants";
import Logo from "../images/GetArcLogo.png";
import Arrow from "../images/GetArcArrow.svg";

const Button = styled.a`
position: relative;
top: -1px;

padding: 0.7em 1em;
padding-bottom: 0.6em;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;

box-sizing: border-box;

text-decoration: none;
cursor: default;
color: #C9CCD8;

min-width: 150px;

transition: color 0.1s ease-in-out, transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;

background-color: #404350;
border-radius: 12px;
border: 0.8px solid rgba(255,255,255,0.2);

box-shadow: 0 0 10px rgba(193, 198, 217, 0.1);

&:hover {
    background-color: #454855;
}
&:active {
    background-color: #303340;
}
`;

const LogoImg = styled.img`
    height: 1.5em;
    margin-right: 0.5em;
`;

const ArrowImg = styled.img`
    height: 0.7em;
    margin-left: 0.7em;
`;

const GetArcButton = () => {
  return (
    <Button href={ARC_NET_URL}>
        <LogoImg src={Logo} alt="" />
        Get Arc
        <ArrowImg src={Arrow} alt="" />
    </Button>
  )
}
export default GetArcButton;
