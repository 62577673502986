import { useState, useCallback, useEffect } from "react";

interface WindowSize {
    innerWidth: number;
    innerHeight: number;
}

const DefaultSize: WindowSize = {
    innerWidth: 1000,
    innerHeight: 1000
};

function curWindowSize(): WindowSize {
    return (typeof window !== 'undefined' && {innerWidth: window.innerWidth, innerHeight: window.innerHeight}) || DefaultSize;
}

export function useWindowSize(): WindowSize {
    // React SSR depends on the client rendering the exact same thing as the server during the first render pass.
    // This means we need to use the default size during the initial pass, and call setState() within useEffect()
    // to trigger an additional pass. (https://github.com/facebook/react/issues/10591)
    const [size, setSize] = useState<WindowSize>(DefaultSize);
    useEffect(() => {
        const onResize = () => setSize(curWindowSize());
        onResize();
        if (typeof window !== 'undefined') {
            window.addEventListener('resize', onResize);
            return () => window.removeEventListener('resize', onResize);
        }
        return undefined;
    }, []);
    return size;
}
